import { create, set } from 'zustand';

export const useAuthStore = create((set) => ({
  firebaseApp: null,
  auth: null,
  firestore: null,
  storage: null,
  user: null,
  setFirebaseApp: (firebaseApp) => set({ firebaseApp }),
  setAuth: (auth) => set({ auth }),
  setFirestore: (firestore) => set({ firestore }),
  setStorage: (storage) => set({ storage }),
  setUser: (user) => set({ user }),
}));
