import { useAuthStore } from '@/stores/AuthStore';
import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { useEffect } from 'react';

export const AuthProvider = ({ children }) => {
  const firebaseApp = useAuthStore((state) => state.firebaseApp);
  const setFirebaseApp = useAuthStore((state) => state.setFirebaseApp);
  const setFirestore = useAuthStore((state) => state.setFirestore);
  const setStorage = useAuthStore((state) => state.setStorage);
  const setAuth = useAuthStore((state) => state.setAuth);

  useEffect(() => {
    if (!firebaseApp) {
      const app = initializeApp({
        apiKey: process.env.NEXT_PUBLIC_API_KEY,
        authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
        projectId: process.env.NEXT_PUBLIC_PROJECT_ID,
        storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
        messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
        appId: process.env.NEXT_PUBLIC_APP_ID,
      });

      setFirebaseApp(app);

      const auth = getAuth(app);
      const db = getFirestore(app);
      const storage = getStorage(app);

      // if (process.env.NODE_ENV === 'development') {
      //   connectAuthEmulator(auth, 'http://localhost:9099');
      //   connectFirestoreEmulator(db, 'localhost', 8080);
      // }

      setAuth(auth);
      setFirestore(db);
      setStorage(storage);
    }
  }, [firebaseApp, setAuth, setFirebaseApp, setFirestore, setStorage]);

  if (!firebaseApp) {
    return null;
  }

  return <>{children}</>;
};
